.content-root.order {
  min-height: 100vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.content-root.order .search-section .duration-search-container {
  display: flex;
  gap: 10px;
}
.content-root.order .search-section .duration-search-container .input-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.content-root.order .search-section .duration-search-container .input-wrapper .date-input {
  height: 48px;
  border: 1px solid #e5e5ec;
  border-radius: 4px;
  padding: 0 10px;
}
.content-root.order .search-section .duration-search-container .apply-button {
  border-radius: 4px;
  padding: 0 10px;
  background-color: #661394;
  color: #fff;
  font-size: 18px;
}/*# sourceMappingURL=order.styles.css.map */