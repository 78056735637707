.gnb-root {
  width: 300px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
}
.gnb-root .logo-container img {
  width: 200px;
}
.gnb-root .gnb-item-container {
  cursor: pointer;
  width: 240px;
  height: 60px;
  color: #111111;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-top: 16px;
  transition: all 300ms ease-in;
}
.gnb-root .gnb-item-container.selected {
  background: linear-gradient(90deg, #a502fb 0%, #5c55fa 100%);
  color: #ffffff;
}
.gnb-root .gnb-item-container:hover {
  background-color: #5c55fa;
  opacity: 0.8;
  color: #ffffff;
}
.gnb-root .gnb-item-container .gnb-item-label {
  background-color: transparent;
}
.gnb-root .logout-btn {
  position: absolute;
  bottom: 30px;
  cursor: pointer;
  width: 240px;
  height: 60px;
  font-size: 20px;
  margin: 32px 0;
  border-radius: 4px;
  background-color: #dbdbdb;
  color: #767676;
}/*# sourceMappingURL=gnb.styles.css.map */