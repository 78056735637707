@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import './color';

* {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  text-decoration: none;
  border: 0;
  outline: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  background-color: auto;
}

body {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f4f5f9;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

button {
  cursor: pointer;
}

.primary-btn {
  cursor: pointer;
  width: 200px;
  height: 48px;
  background-color: $primary-blue;
  border-radius: 4px;
  color: #ffffff;
  font-size: 20px;
  margin: 0px 12px;
}

.delete-btn {
  cursor: pointer;
  width: 200px;
  height: 48px;
  background-color: #ffffff;
  border-radius: 4px;
  color: #dc0000;
  font-size: 20px;
  margin: 0px 12px;
  border: 1px solid #dc0000;
}

.content-root {
  background-color: #e4e4e4;
  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .title-item {
      display: flex;
      flex-direction: column;
      margin: 0px 24px;
      cursor: pointer;

      &.selected {
        .title-label {
          color: $primary-blue;
        }

        .subtitle-label {
          color: $primary-blue;
        }
      }

      .title-label {
        font-size: 28px;
        font-weight: bold;
        color: #464255;
      }

      .subtitle-label {
        font-size: 18px;
        font-weight: 500;
        color: #a3a3a3;
      }
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 12px 0px;
  }

  .table-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .header-section {
      height: 60px;
      background-color: $primary-blue;
      border-radius: 4px;
      margin: 24px 0px 12px;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 16px;

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    }

    .search-section {
      background-color: transparent;
      border-radius: 4px;
      margin: 12px 0;
      padding: 12px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      .input-container {
        display: flex;
        background-color: #ffffff;
        border-radius: 4px;
        overflow: hidden;

        .input-box {
          display: flex;
          align-items: center;

          height: 48px;
          font-size: 16px;
          padding: 0 16px;

          &.search {
            width: 360px;
          }
        }

        .input-button {
          width: 48px;
          cursor: pointer;
          img {
            width: 16px;
          }
        }
      }

      .select-container {
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        border-radius: 4px;

        .select-box {
          cursor: pointer;
          -o-appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          height: 48px;
          padding: 0 40px 0 16px;
          outline: 0;
          font-size: 16px;
        }
      }
    }

    .body-section {
      flex: 1;
      background-color: #ffffff;
      border-radius: 4px;
      margin: 12px 0px 24px;
      padding: 16px;
      min-height: 500px;

      .row {
        display: flex;
        margin: 12px 0px;
        cursor: pointer;
        min-height: 48px;
        align-items: center;

        .item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          .image {
            width: 170px;
            height: 110px;
            background-color: #828282;
          }
        }
      }
    }

    .footer-section {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
