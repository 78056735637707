.login-root {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-root form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.login-root input {
  width: 400px;
  height: 48px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #e5e5ec;
}
.login-root .login-btn {
  cursor: pointer;
  width: 400px;
  height: 48px;
  font-size: 16px;
  background-color: #1379d8;
  color: #ffffff;
}/*# sourceMappingURL=login.styles.css.map */