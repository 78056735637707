.partners-root {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
}
.partners-root .partners-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}/*# sourceMappingURL=partners.styles.css.map */