.settlement-input {
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  padding: 11px 12px;
}

.settlement-date-container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.settlement-date-container .settlement-date {
  flex-grow: 1;
  text-align: center;
}
.settlement-date-container .store-button {
  background-color: #5870fa;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  border-radius: 8px;
  padding: 12px 20px;
}/*# sourceMappingURL=calc.list.style.css.map */