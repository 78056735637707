@import '../../../../styles/color';

.gnb-root {
  width: 300px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;

  .logo-container {
    img {
      width: 200px;
    }
  }

  .gnb-item-container {
    cursor: pointer;
    width: 240px;
    height: 60px;
    color: #111111;
    background-color: #ffffff;
    border-radius: 4px;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    margin-top: 16px;
    transition: all 300ms ease-in;

    &.selected {
      background: linear-gradient(
        90deg,
        $primary-purple 0%,
        $primary-blue 100%
      );

      color: #ffffff;
    }

    &:hover {
      background-color: $primary-blue;
      opacity: 0.8;
      color: #ffffff;
    }

    .gnb-item-label {
      background-color: transparent;
    }
  }

  .logout-btn {
    position: absolute;
    bottom: 30px;
    cursor: pointer;
    width: 240px;
    height: 60px;
    font-size: 20px;
    margin: 32px 0;
    border-radius: 4px;
    background-color: $gray300;
    color: $gray600;
  }
}
