.user-detail-root {
  padding: 157px 56px;
  width: 100%;
}
.user-detail-root .title-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
}
.user-detail-root .title-section .title-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.user-detail-root .title-section .title-container .name {
  font-size: 32px;
  font-weight: bold;
  color: #464255;
}
.user-detail-root .title-section .title-container .email {
  font-size: 18px;
  font-weight: 500;
  color: #a3a3a3;
}
.user-detail-root .title-section .button-wrapper {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.user-detail-root .title-section .button-wrapper .store {
  width: 200px;
  height: 52px;
  background-color: #5870fa;
  color: #fff;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
}
.user-detail-root .content-section {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  width: 100%;
  padding: 24px;
  display: flex;
  gap: 40px;
  justify-content: space-between;
}
.user-detail-root .content-section .column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.user-detail-root .content-section .column .input-item {
  display: flex;
  gap: 8px;
  align-items: center;
}
.user-detail-root .content-section .column .input-item .label {
  width: 56px;
  font-size: 12px;
  font-weight: bold;
}
.user-detail-root .content-section .column .input-item input {
  font-size: 12px;
  max-width: 265px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  padding: 11px 12px;
  height: 40px;
  font-size: 13px;
  font-weight: bold;
}
.user-detail-root .content-section .divider {
  width: 1px;
  height: 160px;
  align-self: center;
  background-color: #e2e2e2;
}/*# sourceMappingURL=user.detail.style.css.map */