$primary-blue: #5c55fa;
$primary-purple: #a502fb;
$white: #ffffff;
$gray100: #f9f9f9;
$gray200: #ebebeb;
$gray300: #dbdbdb;
$gray400: #cccccc;
$gray500: #999999;
$gray600: #767676;
$black: #111111;
$error: #dc0000;
$success: #46b712;
